// import ethereum, { PRESALE_PRICE, SALE_PRICE } from "./ethereum";
import tron, { PRESALE_PRICE, SALE_PRICE } from "./tron";

function getTimeRemaining(start, end){
  const total = (end - start) / 1000;
  // const seconds = Math.round( total % 60 );
  // const minutes = Math.floor( (total/60) % 60 );
  // const hours = Math.floor(total/(60*60) );
  const seconds = Math.floor(total % 60);
  const minutes = Math.floor(total / 60) % 60;
  const hours = Math.floor(total / 60 / 60);

  // return [hours, minutes, (seconds === 60) ? 59 : seconds];
  return [hours, minutes, seconds];
}

// 倒计时相关
export const countdownMixin = {
  data() {
    return {
      stage: 0, // 0-initial 1-notstart 2-presale 3-publicsale 4-end
      countdownArr: [], // h-m-s
      utcNow: null,
      start: {
        dd:'', hh:'', mm:'', ss:''
      },
    }
  },
  created() {
    // this.countdown();
    // this.countdownInterval = setInterval(this.countdown, 1000);
  },
  beforeDestroy() {
    // clearInterval(this.countdownInterval);
  },
  methods: {
    countdown() {
      let now = Date.now();
      let utcNow = new Date(now).toISOString();
      if(this.startToNow) {
        now = now + this.startToNow;
        utcNow = new Date(now).toISOString();
      }
      const presaleStage = Date.UTC('2022', '0', '26', '14', '00', '00');
      const saleStage = Date.UTC('2022', '0', '27', '14', '00', '00');
      const endStage = Date.UTC('2022', '0', '31', '14', '00', '00');
      if (now < presaleStage) {
        // 未到开售期
        this.stage = 1;
        this.countdownArr = getTimeRemaining(now, presaleStage);
      } else if (now < saleStage) {
        // 预售期
        this.stage = 2;
        this.countdownArr = getTimeRemaining(now, saleStage);
      } else if (now < endStage) {
        // 公售期
        this.stage = 3;
        this.countdownArr = getTimeRemaining(now, endStage);
      } else {
        this.stage = 4;
      }
      this.utcNow = utcNow;
    },
    set() {
      clearInterval(this.countdownInterval);
      const { dd, hh, mm, ss } = this.start;
      this.startToNow = Date.UTC('2022', '0', dd || 0, hh || 0, mm || 0, ss || 0) - Date.now();
      this.countdownInterval = setInterval(this.countdown, 1000);
      this.isSetTimeOpen = false;
    },
    reset() {
      clearInterval(this.countdownInterval);
      this.startToNow = 0;
      this.start = {
        dd:'', hh:'', mm:'', ss:''
      }
      this.countdownInterval = setInterval(this.countdown, 1000);
      this.isSetTimeOpen = false;
    },

  },
}

// about mint
const SALE_MAX_PER_TIME = 10;
export const walletMixin = {
  data() {
    return {
      PRESALE_PRICE,
      SALE_PRICE,
      isConnected: false,
      isConnecting: false,
      hasQuriedNum: false,
      mintNum: 1, // chose by user
      isMinting: false,
      presaleBuyableCount: 0,
      buyableCount: 0,
    }
  },
  computed:{
    max() {
      if(!this.hasQuriedNum) return 10000;
      return this.buyableCount;
    },
    minusDisabled() {
      return this.mintNum <= 1 || this.max == 0;
    },
    addDisabled() {
      if(!this.isConnected) return this.mintNum >= SALE_MAX_PER_TIME;
      return this.mintNum >= Math.min(this.max, SALE_MAX_PER_TIME);
    },
    canMint() {
      if(!this.isConnected) return false;
      return (this.mintNum >= 1) && (this.mintNum <= this.max);
    },
    isSoldOut() {
      return this.hasQuriedNum && this.buyableCount <= 0;
      // return true;
    }
  },
  watch: {
    max(val) {
      console.log('max', val)
      this.validateMintNum()
    },
  },
  methods: {
    async connectWallet() {
      this.isConnecting = true;
      try {
        await tron.init();
        this.isConnecting = false;
        this.isConnected = true;
        this.triggerQueryAccount();
      } catch (e) {
        // console.log('e', e);
        // e.message
        this.$toast.warning(typeof e === 'string' ? e : e.message);
        this.isConnecting = false;
      }
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
         $event.preventDefault();
      }
    },
    triggerQueryAccount() {
      if(!this.isConnected) return;

      clearInterval(this.queryAccountInterval);
      this.queryBuyableCount();
      this.queryAccountInterval = setInterval(this.queryBuyableCount, 10*1000);
    },
    async queryBuyableCount() {
      try{
        const count = await tron.queryBuyableCount();
        this.buyableCount = count;
        this.hasQuriedNum = true;
        console.log('update sale count', count);
      } catch(e) {
        console.log('queryBuyableCount err', e);
        this.$toast.warning(typeof e === 'string' ? e : e.message);
        this.hasQuriedNum = false;
      }
    },
    onBlur() {
      this.validateMintNum(this.mintNum);
    },
    minusMintNum() {
      if(this.minusDisabled) return;
      this.mintNum -= 1;
    },
    addMintNum() {
      if(this.mintNum == SALE_MAX_PER_TIME) {
        this.$toast.warning('Up to 10 at a time')
      }
      if(this.addDisabled) return;
      this.mintNum += 1;
    },
    validateMintNum() {
      if(this.max == 0) this.mintNum = 0;
      else if(this.mintNum > this.max) {
        setTimeout(() => {
          this.mintNum = this.max;
        }, 100)
      }
      else if(this.mintNum <= 0) {
        setTimeout(() => {
          this.mintNum = 1;
        }, 100)
      }
    },
    triggerMint() {
      if(!this.canMint) return;
      if(this.mintNum < 1) return;
      this.mint(this.mintNum);
    },
    async mint(num) {
      try {
        this.isMinting = true;
        const res = await tron.mint(num);
        console.log('mint complete, res--', res);
        this.isMinting = false;
        this.$toast.success('mint succeeds');
      } catch(e) {
        this.isMinting = false;
        this.$toast.warning(typeof e === 'string' ? e : e.message);
      }
    }
  }
}
const mainnetContract = 'THEBUEidYHA8uku8iGL1TD6QZUaTirKyX1';
// const mainnetContract = "TC2DyJuJbwBYv2z5vxYBk5TCBS8PgJFjR5";
const TOTAL = 10000;
export const SALE_PRICE = 0.1;
const PRESALE_PRICE_RATIO = 0.8;
export const PRESALE_PRICE = (SALE_PRICE * PRESALE_PRICE_RATIO).toFixed(2);

// const checkIfCorrectChain = (chainName) => !chainName.includes('api.trongrid.io');
const checkIfCorrectChain = (chainName) => !chainName.includes('shasta');

window.addEventListener('message', function (e) {
  if (e.data.message && e.data.message.action == 'setNode') {
    this.location.reload();
  }
});

export default {
  isInited: false,
  async init() {
    if (!window.tronWeb) {
      throw 'Please install Wallet!';
    }
    if (!window.tronLink) {
      throw 'No window.tronLink';
    }
    const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
    if (res.code == 4001) throw new Error('User reject connect');

    const tronWeb = window.tronWeb;
    if (checkIfCorrectChain(tronWeb.eventServer.host)) {
      // throw new Error('Please switch to the mainnet');
      throw new Error('Please switch to the shasta network');
    }

    window.contract = await window.tronWeb.contract().at(mainnetContract);
  },
  async queryBuyableCount() {
    // const callParameters = {
    //   to: mainnetContract,
    //   from: window.ethereum.selectedAddress,
    //   data: "0xfc98183c",
    // };
    const sold = await window.contract.totalSupply().call();
    return Math.max(TOTAL - sold, 0);
  },
  async mint(qty) {
    const transactionParameters = {
      callValue: 500 * 1000000 * qty,
      // shouldPollResponse:true,
    };
    return window.contract.mint(qty).send(transactionParameters);
  },
};

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import Toast from "vue-toastification";
import "./toast.scss";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Meta);

Vue.use(Toast, {
  hideProgressBar: true
});

new Vue({
  render: h => h(App),
  router, 
}).$mount('#app');

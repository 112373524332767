<template>
  <div id="mint-container">
    <pc></pc>
  </div>
</template>
<script>
import Pc from './pc/index.vue';

export default {
  name: 'mint',
  data() {
    return {
    }
  },
  components: {
    pc: Pc, 
  },
  created() {
    
  },
  beforeDestroy() {
    
  },
  methods:{
    
  }
}
</script>
<style scoped>
#mint-container {
  color: #fff;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
}
</style>

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'TPieceOfShit',
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover',
      },
    ],
    script: [
      {
        // src: 'https://cdnjs.cloudflare.com/ajax/libs/web3/1.7.3/web3.min.js',
        // src: 'https://cdn.jsdelivr.net/npm/web3@1.7.0/dist/web3.min.js',
      },
      {
        src: 'https://peterolson.github.io/BigInteger.js/BigInteger.min.js',
      },
    ],
  },
  mounted() {},
};
</script>

<style>
body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
input {
  border-width: 0px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  outline: none;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

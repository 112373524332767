<template>
  <div id="pc-container">
    <div id="pc-mask"></div>
    <div id="pc-main" class="center">
      <!-- left logo -->
      <div id="pc-left-logo" class="center">
        <a href="https://tpieceofshit.wtf" target="_blank" class="logo home-logo" style="margin-right: 23px">Home</a>
        <a href="https://tpieceofshit.wtf" target="_blank" class="logo center" style="margin-right: 24px"
          ><img style="width: 250px; margin-right: 6px" src="@/assets/pc/title.png"
        /></a>
      </div>
      <!-- right link -->
      <div id="pc-right-icon" class="center">
        <a href="https://testnet.apenft.io/collections/TPieceOfShit" target="_blank"
          ><img style="width: 66px; height: 61px; margin-right: 5px" src="@/assets/pc/icon1.png"
        /></a>
        <a href="https://t.me/TPieceofShit" target="_blank"
          ><img style="width: 66px; height: 61px; margin-right: 5px" src="@/assets/pc/icon2.png"
        /></a>
        <a href="https://twitter.com/T_PieceofShit" target="_blank"
          ><img style="width: 66px; height: 61px" src="@/assets/pc/icon3.png"
        /></a>
      </div>

      <div id="pc-center">
        <div id="pc-moto" class="center">
          <img src="@/assets/pc/shit.png" style="width: 302px; margin-top: 40px" />
        </div>
        <div id="pc-center-right" class="center">
          <stage-23 />
        </div>
      </div>

      <!-- bottom -->
      <div id="pc-bottom" class="center">Your NFT is just fucking piece of shit.</div>
    </div>
  </div>
</template>
<script>
import { countdownMixin } from '../mixin';
import Stage23 from './stage23.vue';

export default {
  name: 'mint-pc',
  mixins: [countdownMixin],
  components: {
    stage23: Stage23,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="less">
@font-face {
  font-family: bebas;
  src: url('~@/assets/bebas.ttf');
}
#pc-container {
  width: 100vw;
  min-width: 1080px;
  height: 100vh;
  background-image: url('~@/assets/pc/back.png');
  background-size: cover;
  position: relative;
}
#pc-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  // background: rgba(0, 0, 0, 1);
}
#pc-main {
  position: relative;
  width: 100%;
  height: 100%;
}
#pc-left-logo {
  position: absolute;
  left: 40px;
  top: 50px;
  font-weight: bold;
  color: #fff;
  .logo {
    color: #fff;
    font-weight: normal;
    font-size: 16px;
  }
  .home-logo {
    background: #bc291d;
    border-radius: 10px;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-top: -10px;
  }
}
#pc-right-icon {
  position: absolute;
  top: 50px;
  right: 40px;
}
#pc-center {
  display: flex;
  padding-bottom: 20px;
  padding-top: 0px;
  #pc-moto {
  }
  #pc-center-right {
    width: 300px;
    margin-left: 51px;
    justify-content: flex-start;
  }
}
#pc-bottom {
  position: absolute;
  width: 100%;
  bottom: 25px;
  color: #fff;
  font-weight: lighter;
  font-size: 14px;
  letter-spacing: 3.5px;
}

#pc-set {
  position: absolute;
  bottom: 70px;
  border: 1px dashed;
  padding: 0 5px;
  font-size: 13px;
}
</style>
